<template lang="pug">
.element-view(
  :id='`element-${element.id}`'
  :style='this.getStyle()'
  v-if='!loading'
)
  template(v-if='element.category === "content"')
    .element-view__text(v-if='element.type === "text"') {{ element.value }}
    .element-view__comment(
      v-if='element.type === "comment"'
      v-tooltip:top='`Anotação do remetente`'
    ) {{ formatTextFieldWhenIsDate(element.value) }}
    .element-view__qr-code(v-if='element.type === "validation-qrcode"')
      img.img.img-fluid(src='/images/qr-code.svg')
    .element-view__image(v-if='element.type === "image"')
      img.img(:src='element.image.src')
  template(v-if='element.category === "field"')
    template(v-if='element.recipient.id === recipientId && !element.disabled')
      .element-view__field-input(
        v-if='element.type === "text"'
        v-tooltip:top='element.tooltip'
      )
        input.input(
          :placeholder='element.title'
          @change='$emit("change", element)'
          type='text'
          v-model='element.value'
        )
      .element-view__field-select(
        v-if='element.type === "dropdown"'
        v-tooltip:top='element.tooltip'
      )
        select.form-select(
          @change='$emit("change", element)'
          v-model='element.value'
        )
          option(disabled value='') {{ element.title }}
          option(
            :key='index'
            :value='option'
            v-for='(option, index) in element.options'
          ) {{ option }}
      .element-view__field-checkbox(
        v-if='element.type === "checkbox"'
        v-tooltip:right='element.tooltip'
      )
        label.label(:for='element.id')
          input.form-check-input(
            :id='element.id'
            @change='$emit("change", element)'
            type='checkbox'
            v-model='element.value'
          )
      .element-view__field-signature(
        :class='{ "no-image": !element.value }'
        v-if='element.type === "signature"'
      )
        div(
          @click='$emit("signature-click", element)'
          style='cursor: pointer'
          v-if='!element.value'
        )
          span.text Assinar aqui
          div
            icon(name='file-signature')
        img.img.img-fluid(:src='globalSignature || element.value' v-else)
        button.btn.btn-edit-field(@click='$emit("edit-signature", element)')
          icon(name='pen')

      .element-view__field-initial(
        :class='{ "no-image": !element.value }'
        v-if='element.type === "initial"'
      )
        div(
          @click='$emit("initial-click", element)'
          style='cursor: pointer'
          v-if='!element.value'
        )
          span.text Rubricar aqui
          div
            icon(name='file-signature')
        img.img.img-fluid(:src='globalInitial || element.value' v-else)
        button.btn.btn-edit-field(@click='$emit("edit-initial", element)')
          icon(name='pen')
    template(v-else)
      template(v-if='element.type === "signature"')
        .element-view__field-no-owner.d-flex(v-if='!element.value')
          .element-view__field-no-owner__content.my-auto
            .text Assinatura
            .text.cut-with-ellipsis {{ element.recipient.addressees[0].name }}
            //- .text.cut-with-ellipsis {{ element.recipient.addressees[0].value }}
        .element-view__field-signature(v-else)
          img.img.img-fluid(:src='element.value')
      template(v-if='element.type === "initial"')
        .element-view__field-no-owner.d-flex(v-if='!element.value')
          .element-view__field-no-owner__content.my-auto
            .text Rubrica
            .text.cut-with-ellipsis {{ element.recipient.addressees[0].name }}
            //- .text.cut-with-ellipsis {{ element.recipient.addressees[0].value }}
        .element-view__field-signature(v-else)
          img.img.img-fluid(:src='element.value')
      template(v-if='element.type === "checkbox"')
        .element-view__field-checkbox(
          v-tooltip:right='`${element.recipient.addressees[0].name} - ${element.title}`'
        )
          label.label(:for='element.id')
            input.form-check-input(
              :checked='element.value'
              :id='element.id'
              disabled
              type='checkbox'
            )
      template(v-if='["text", "dropdown"].includes(element.type)')
        .element-view__field-input(
          v-if='element.type === "text"'
          v-tooltip:top='`${element.recipient.addressees[0].name} - ${element.title}`'
        )
          input.input(
            :placeholder='element.title'
            :value='element.value'
            disabled
            type='text'
          )
</template>
<script>
/* eslint-disable vue/no-mutating-props */

import Icon from '@/ui/atoms/Icon.vue';
import ElementResponsiveConverter from '@/modules/business/services/ElementResponsiveSizeConverter';

export default {
  props: {
    element: Object,
    page: Object,
    recipientId: String,
    globalSignature: String,
    globalInitial: String,
    scale: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      loading: true,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      fontSize: 16,
      parentSizeObserve: '',
      parentSizeObserveUpdateDelay: 100,
      loadedPageObserver: null,
    };
  },
  created() {
    this.loading = true;
    this.element.tooltip = '';
    if (this.element.type === 'dropdown' && !this.element.value) {
      this.element.value = '';
    }
    if (this.element.type === 'dropdown') {
      if (this.element.required) {
        this.element.tooltip = `Obrigatório - ${this.element.title}`;
      } else {
        this.element.tooltip = `Opcional - ${this.element.title}`;
      }
    }
    if (this.element.type === 'text') {
      if (this.element.required) {
        this.element.tooltip = `Obrigatório - ${this.element.title}`;
      } else {
        this.element.tooltip = `Opcional - ${this.element.title}`;
      }
    }
    if (this.element.type === 'checkbox') {
      if (this.element.required) {
        this.element.tooltip = `Obrigatório - ${this.element.title}`;
      } else {
        this.element.tooltip = `Opcional - ${this.element.title}`;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.init();
      this.loading = false;

      this.loadedPageObserver = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.target.classList.contains('loaded')) {
            this.init();
          }
        }
      });

      this.loadedPageObserver.observe(this.$el.parentElement.parentElement, {
        attributes: true,
      });
    }, 1000);
  },
  methods: {
    formatTextFieldWhenIsDate(element) {
      if (!element) return element;
      const formattedDate = this.$options.filters.dateTime(element);
      return formattedDate === 'Invalid Date' ? element : formattedDate;
    },
    init() {
      if (!this.$el.parentElement.clientWidth) {
        return;
      }
      const converter = new ElementResponsiveConverter();
      converter.setParentElementSize(
        this.page.size.width,
        this.page.size.height
      );
      converter.setElementSize(this.element.apiWidth, this.element.apiHeight);
      converter.setElementPosition(this.element.apiX, this.element.apiY);
      converter.setElementFontSize(this.element.apiFontSize || 16);
      const positionPercentage = converter.getElementPositionPercentage();
      const sizePercentage = converter.getElementSizePercentage();
      const fontSizePercentage = converter.getElementFontSizePercentage();

      // save original positions
      this.originalX = positionPercentage.x;
      this.originalY = positionPercentage.y;
      this.originalWidth = sizePercentage.width;
      this.originalHeight = sizePercentage.height;
      this.originalFontSize = fontSizePercentage;

      // Init calc positions
      this.recalculatePositions(this.scale);
    },
    recalculatePositions(scale) {
      // recalculate positions if parent element size changes
      if (!this.$el) return;

      const parentElement = this.$el.parentElement;
      const { scrollLeft, scrollTop } = parentElement;
      const { width: pageWidth, height: pageHeight } = this.page.size;

      const newClientWidth = pageWidth * scale;
      const newClientHeight = pageHeight * scale;

      this.x = (this.originalX * newClientWidth) / scale - scrollLeft;
      this.y = (this.originalY * newClientHeight) / scale - scrollTop;
      this.width = (this.originalWidth * newClientWidth) / scale;
      this.height = (this.originalHeight * newClientHeight) / scale;
      this.fontSize = this.originalFontSize * this.width;
    },
    getStyle() {
      const scaleFactor = 1 / this.scale;
      return {
        transform: `scale(${this.scale})`,
        width: `${this.scaledPositions.width * this.scale}px`,
        height: `${this.scaledPositions.height * this.scale}px`,
        left: `${this.scaledPositions.x * scaleFactor}px`,
        top: `${this.scaledPositions.y * scaleFactor}px`,
        fontSize: `${this.scaledPositions.fontSize * this.scale}px`,
      };
    },
  },
  computed: {
    scaledPositions() {
      this.recalculatePositions(this.scale);
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        fontSize: this.fontSize,
      };
    },
  },
  watch: {
    scale: {
      immediate: true,
      handler(newScale) {
        this.recalculatePositions(newScale);
      },
    },
  },
};
</script>
<style lang="stylus">
.element-view
  position: absolute
  &.focus
    box-shadow: 0 0 0 5px #01AFEC
    border-radius: 0.3em
  .cut-with-ellipsis
    text-overflow: ellipsis
    overflow: hidden
    width: auto
    white-space: nowrap
    display: block
.element-view__text,
.element-view__comment,
.element-view__qr-code,
.element-view__image,
.element-view__field-input,
.element-view__field-select,
.element-view__field-checkbox,
.element-view__field-signature,
.element-view__field-initial
  background-color: #fff
  border: 1px solid #000
  border-radius: .3em
.element-view__text, .element-view__comment
  color: #000
.element-view__text
  padding: .5em .5em
  text-align: center
  line-height: 1.4em
.element-view__comment
  line-height: 1.5em
  padding: 1em
.element-view__qr-code, .element-view__image
  .img
    width: 100%
    padding: .1em
.element-view__field-select
  padding: 1em
  .form-select
    color: #000
    border-radius: 0
    height: 2.7em
    line-height: 1em
    font-size: inherit
    padding: 0 1em
    background-position: right .75em center
    background-size: 1em 1em
.element-view__field-checkbox
  .form-check-input
    width: 1.5em
    height: 1.5em
    margin: 0
  .label
    padding: 1em .9em
    width: 100%
    text-align: center
.element-view__field-input
  padding: .5em
  .input
    width: 100%
    padding: .2em .4em
    line-height: 1em
    border: .1em solid #ccc
.element-view__field-signature, .element-view__field-initial
  color: #fff
  width: 100%
  height: inherit
  border-radius: inherit
  text-align: center
  border: .1em dashed #ccc
  border-radius: .3em
  position: relative
  &.no-image
    background-color: #013D52
    padding: 1em
    border: none
    display: flex
    align-items: center
    justify-content: center
    .btn-edit-field
      display: none
  .btn-edit-field
    position: absolute
    border-radius: 50%
    background-color: #3ecb90
    height: 2em
    width: 2em
    padding: .1em
    top: -1em
    right: -.5em
    .icon
      margin: auto
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      width: 1em
      height: 1em
  .img
    margin: auto
    position: absolute
    left: 0
    right: 0
    max-width: 100%
    top: 0
    bottom: 0
    padding: .1em
    max-height: 100%

.element-view__field-no-owner
  height: inherit
  border: .1em dashed #555555
  padding: .5em 1em
  background-color: #fff
  font-size: .9em

.element-view__field-no-owner__content
  max-width: 100%
</style>
