import enumBase from './EnumBase';

export default class PlanIcon extends enumBase {
  static values = [
    { value: 'Básico', label: 'leaf' },
    { value: 'Essencial', label: 'briefcase' },
    { value: 'Avançado', label: 'building' },
    { value: 'Corporativo', label: 'rocket' },
  ];

  static getByName(name) {
    const item = this.values.find((i) => i.value === name);
    return item ? item.label : 'leaf';
  }
}
