<template lang="pug">
.modal(ref='modal' tabindex='-1')
  .modal-dialog(:class='[{ loaded: !loading }, `modal-${size}`]')
    .modal-content
      .modal-header(v-if='!noHeader')
        h5.modal-title {{ title }}
        button.close(
          @click='close'
          type='button'
          v-if='!removeCloseButton'
        )
          span(aria-hidden='true') &times;
      .modal-body
        slot
      .modal-footer(v-if='!noFooter')
        div(v-if='auxButtonShow')
          button.btn.btn-thin.flex-fill(
            @click='$emit("auxButtonAction")'
            v-if='auxButtonShow'
          )
            span(aria-hidden='true') {{ auxButtonLabel }}
        .d-flex.flex-grow-1.justify-content-end
          button.btn.btn-secondary(
            @click='cancel'
            type='button'
            v-if='showCancelButton'
          ) {{ closeButtonLabel }}
          button#buttonColor.btn.ms-3(
            :class='[processButtonClass]'
            :disabled='disableProcessButton'
            @click='$emit("process")'
            type='button'
            v-if='processButtonLabel'
          )
            | {{ processButtonLabel }}
</template>

<script>
export default {
  name: 'ModalMolecule',
  props: {
    loading: Boolean,
    title: String,
    closeButtonLabel: String,
    processButtonLabel: String,
    buttonColor: Boolean,
    processButtonClass: {
      type: String,
      default: 'btn-primary',
    },
    noFooter: {
      type: Boolean,
      default: false,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    disableProcessButton: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    removeCloseButton: Boolean,
    auxButtonLabel: {
      type: String,
      default: '',
    },
    auxButtonShow: {
      type: Boolean,
      default: false,
    },
    auxButtonAction: Function,
    cancelAction: Function,
  },
  data() {
    return {
      modal: null,
      modalElement: null,
    };
  },
  mounted() {
    this.modalElement = document
      .querySelector('body')
      .appendChild(this.$refs.modal);
    this.modal = new window.bootstrap.Modal(this.modalElement);
    this.modalElement.addEventListener('hidden.bs.modal', () => {
      this.$emit('closed');
    });
    this.modalElement.addEventListener('show.bs.modal', () => {
      this.$emit('opened');
    });
  },
  destroyed() {
    this.modal.dispose();
    this.modalElement.remove();
  },
  methods: {
    close() {
      this.modal.hide();
    },
    cancel() {
      if (this.cancelAction instanceof Function) {
        this.cancelAction();
        return;
      }
      this.close();
    },
    open() {
      this.modal.show();
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal
  z-index: 5555
.modal-dialog
  margin-top: 5rem
  &.loaded
    .modal-content
      opacity: 1
    .modal-loading
      opacity: 0
.modal-footer
  justify-content: space-between
  >*
    margin: 0
  button
    margin: .25rem
@media screen and (min-width: 576px)
  .modal-sm
    max-width: 420px
.btn-danger
  border-color: #d64242;
  background-color: #d64242;
  color: #fff;
</style>
