import enumBase from './EnumBase';

export default class PlanIntervalText extends enumBase {
  static values = [
    { value: 1, label: 'mês' },
    { value: 3, label: 'trimestre' },
    { value: 6, label: 'semestre' },
    { value: 12, label: 'ano' },
  ];

  static getByInterval(interval) {
    const item = this.values.find((i) => i.value === interval);
    return item ? item.label : '(' + interval + ') mes(es)';
  }
}
