<template lang="pug">
.row.configuration-screen
  .col-12
    ul#myTab.nav.nav-tabs
      li.nav-item.active
        button.nav-link.active(
          data-bs-target='#tab__personalizar-organizacao'
          data-bs-toggle='tab'
        ) Personalizar organização
    .tab-content
      #tab__personalizar-organizacao.tab-pane.fade.show.active(
        aria-labelledby='home-tab'
        role='tabpanel'
      )
      .row.mt-3
        .col-12.col-lg-4.mb-3
          .text-color--blue
            span.text Nome da organização
          .card.card--name.mt-3
            .card-body
              .col-12.form-group.mb-4
                label(for='name') Nome
                .form-control-icon
                  icon(name='building')
                  input#name.form-control(
                    :disabled='!isOwner'
                    autocomplete='off'
                    maxlength='50'
                    placeholder='Insira o nome da sua organização'
                    type='text'
                    v-model='organizationName'
                  )
              .col-12.text-end
                button.m-auto.btn.btn-primary.btn-small-screen(
                  :disabled='canSubmitOrganizationName'
                  @click='submitOrganizationName()'
                  type='button'
                ) Salvar alteração
        .col-12.col-lg-8
          .mb-2
            .text-color--blue
              span.text Logo
            .card.card--logo.mt-3
              .card-body
                button.upload-button(
                  :disabled='!isOwner'
                  @click='$refs.inputFile.click()'
                )
                  .card.card--file-loader(v-if='!fileCropped')
                    .insert-file(v-if='!hasOrganizationLogo')
                      .text.text-color--blue
                        icon.me-2(name='up-from-bracket')
                        span.text Inserir Logo
                      .text.text-color--gray Imagem em JPG e PNG na proporção: 190 x 33 px
                    .load-file(v-else)
                      img(
                        :src='$store.state.organizations.selected.logo'
                        alt='organization logo'
                      )
                      .overlay
                        icon(name='pen-to-square')
                .file-upload.d-flex.justify-content-center(v-if='fileCropped')
                  .file-content
                    .file-item
                      span.file-name {{ fileName }}
                      button(@click='removeFile()')
                        icon(name='trash')
                input.d-none(
                  @change='imageFileChangeEvent($event)'
                  accept='image/*'
                  ref='inputFile'
                  type='file'
                )
                .row.mt-4
                  .col-12.col-lg-8.mb-3.mb-lg-0
                    .text A logo inserida será aplicada nos seguintes locais:
                    .text
                      icon.me-2(name='display')
                      span.text Site
                      icon.me-2.ms-2(name='at')
                      span.text E-mail
                  .col-12.col-lg-4.text-end
                    button.btn.btn-primary.btn-small-screen(
                      :disabled='canSubmitOrganizationLogo'
                      @click='submitOrganizationLogo()'
                      type='button'
                    ) Salvar alteração
                  a(
                    @click='deleteOrganizationLogo()'
                    style='cursor: pointer'
                    v-if='hasOrganizationLogo'
                  )
                    span
                      icon.icon--canceled(name='trash-can')
                    span.text.delete-link Excluir Logo
  crop-Image-modal(
    :dimensions='cropDimensions'
    :file='files'
    @processCroppedImage='processCroppedImage'
    ref='updateAvatarModal'
  )
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import CropImageModal from '@/ui/organisms/modals/CropImageModal.vue';
import OrganizationService from '@/services/OrganizationsHttp';
import alert from '@/modules/account/common/alert';
import AppConfig from '@/config';

export default {
  name: 'ConfigurationPage',
  components: {
    Icon,
    CropImageModal,
  },
  props: {
    currentOrganization: [Object, Boolean],
  },
  data() {
    return {
      organizationName: '',
      files: null,
      fileName: null,
      fileCropped: null,
      cropDimensions: {
        width: 190,
        height: 33,
      },
    };
  },
  async created() {
    this.organizationName = this.currentOrganization.name;
    this.$loading(true);
    try {
      this.$store.dispatch('page/setTitle', 'Configurações');
      this.$store.dispatch('page/setHead', 'Configurações');
    } finally {
      this.$loading(false);
    }
  },
  computed: {
    canSubmitOrganizationName() {
      if (this.organizationName !== this.currentOrganization.name) {
        return false;
      }
      return true;
    },
    canSubmitOrganizationLogo() {
      return this.fileCropped ? false : true;
    },
    hasOrganizationLogo() {
      if (
        this.$store.state.organizations.selected.logo ==
        `${AppConfig.cdnPath}/assets/images/rebrand-intellisign/intellisign-logo.png`
      ) {
        return false;
      }
      return true;
    },
    isOwner() {
      return this.$store.state.organizations.selected.is_owner ? true : false;
    },
  },
  watch: {
    currentOrganization(organization) {
      this.organizationName = organization.name;
    },
  },
  methods: {
    submitOrganizationName() {
      this.$loading(true);
      this.$store
        .dispatch('organizations/updateItem', {
          id: this.currentOrganization.id,
          name: this.organizationName,
        })
        .then(() => {
          this.$store.state.organizations.selected.name = this.organizationName;
          this.$store.dispatch(
            'organizations/setSelected',
            this.$store.state.organizations.selected
          );

          alert.fireAlert('Nome alterado com sucesso.', {
            classes: 'alert-success',
            styles:
              'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
            icon: 'circle-check',
          });
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    async submitOrganizationLogo() {
      this.$loading(true);
      await OrganizationService.updateLogo(
        this.$store.state.organizations.selected.id,
        this.fileCropped
      )
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit(
              'organizations/setOrganizationLogo',
              response.data.logo
            );

            alert.fireAlert('Imagem salva com sucesso.', {
              classes: 'alert-success',
              styles:
                'background-color: #d4edda; border-color: #c3e6cb; color: #155724;',
              icon: 'circle-check',
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            alert.fireAlert('Imagem não deve ser maior que 2048 kilobytes.', {
              classes: 'alert-danger',
              styles:
                'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
              icon: 'triangle-exclamation',
            });
          }
          if (error.response.status === 401) {
            alert.fireAlert(
              'Não foi possível completar a ação, entidade não processável',
              {
                classes: 'alert-danger',
                styles:
                  'background-color: #f8d7da; border-color: #f5c6cb; color: #721c24;',
                icon: 'triangle-exclamation',
              }
            );
          }
        })
        .finally(() => {
          this.removeFile();
          this.$loading(false);
        });
    },
    async deleteOrganizationLogo() {
      this.$loading(true);
      try {
        await OrganizationService.deleteLogo(
          this.$store.state.organizations.selected.id
        );
        this.removeFile();
      } finally {
        window.location.reload();
      }
    },
    imageFileChangeEvent($event) {
      if ($event.target.files[0].type.indexOf('image/') === -1) {
        console.error('type file not accept');
        this.$refs.inputFile.value = null;
        return;
      }
      if ($event.target.files.length) {
        this.fileName = $event.target.files[0].name;
        this.files = new Blob($event.target.files, { type: 'imag/png' });
        this.$refs.updateAvatarModal.$refs.modal.open();
        this.$refs.inputFile.value = null;
      }
    },
    processCroppedImage(result) {
      this.fileCropped = result;
    },
    removeFile() {
      this.fileCropped = null;
    },
  },
};
</script>

<style lang="stylus" scoped>
.delete-link
  color: red;
  padding-left: 0.313rem
.configuration-screen
  .btn-small-screen
    @media screen and (max-width: 1200px)
      width: 100%
  .card
    border-radius: 0.313rem
    label
      font-size: 1rem
  .text-color--blue
    color: #013D52
    font-size: 0.875rem
    font-weight: 400
  .text-color--gray
    color: #555555
  .card--name, .card--logo
    min-height: 14rem
  .card--name
    .card-body
      padding: 1.875rem 1.875rem
  .card--logo
    .card-body
      padding: 1.875rem 1.875rem
    .upload-button
      width: 100%
      padding: 0
    .card--file-loader
      text-align: center
      border: 1px dashed #3ECB90
      border-radius: 5px;
      font-size: 0.875rem
      &:hover
        border: 1px solid white
    .insert-file
      padding: 1rem
      .text
        &:first-child
          font-size: 1rem
      &:hover
        background-color: #f7f7f7
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .1)
    .load-file
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4.375rem
      img
        width: 11.875rem
        height: 2.063rem
      &:hover
        border-radius: 5px;
        background-color: #4d7787
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .1)
        img
          opacity: 0.3;
      .overlay
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        &:hover
          opacity: 1
        .icon
          color: white;
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
    .file-upload
      padding-top: 0.438rem;
      display: flex !important
      .file-content
        position: relative
        width: 24.313rem
        border-radius: 3px
        background-color: #FFFFFF
        border: 1px solid #e0e6ed
        .file-item
          display: flex
          justify-content: space-between
          align-items: center;
          padding: 1rem
          height: 3.188rem
          .file-name
            text-align: left
            font-size: .875rem
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
</style>
