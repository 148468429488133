<template lang="pug">
.mb-5
  envelope-step-bar(:step='1')
  .alert.alert-warning.alert-dismissible.fade.show(
    role='alert'
    v-if='dontHaveCredits'
  )
    div(style='display: flex; align-items: center')
      icon.icon-left(name='circle-exclamation')
      span(style='font-weight: bold; margin-right: 10px')
        | Você atingiu o limite de envios deste {{ planIntervalName }}.
      button.close(
        aria-label='Close'
        data-bs-dismiss='alert'
        type='button'
      )
        span &times
    div
      | Aguarde a renovação automática no próximo ciclo ou
      |
      button.btn-link-menu(type='button')
        router-link.plan-details-link(
          :to='{ name: "account.billing.plan", query: { tab: "plans" } }'
        ) realize um upgrade
      |
      | de plano para continuar enviando envelopes agora.

  .card.card--file-loader.mb-5
    .card-body(
      :class='["dropzone-area", dragging ? "dragenterClass" : ""]'
      @dragend='dragging = false'
      @dragenter='dragging = true'
      @dragleave='dragging = false'
      @dragover.prevent='dragover'
      @drop.prevent='drop'
    )
      input(
        :accept='allowedFileType.join()'
        multiple='multiple'
        ref='inputFile'
        style='display: none'
        type='file'
      )
      label.notclickable.block.cursor-pointer(for='assetsFieldHandle')
        h3.card-title Arraste aqui seus documentos
        p.font-size-subtitle.mb-2 ou
        button.btn.btn-thin(@click='openfileUploadModal') Buscar em
        p.font-size-small.mb-0.mt-2 Arquivos {{ allowedFileExtensions | fileExtensions }},
          |
          | com no máximo {{ maxFileSizeMB }}MB
  .files-list.mb-5(v-if='files.length > 0')
    h6.mb-4 Arquivos selecionados
    .w-100.d-flex.flex-wrap
      .clearfix.mb-4.col-md-3.col-sm-4(v-for='(file, index) in files')
        template(v-if='file.file')
          .file-item(:title='file.file.name')
            .actions(v-if='!file.loading')
              button(@click='removeFile(file)' title='Remover')
                icon(name='trash')
            .preview.d-flex(:style='`background-color: #F0F0F0`')
              template(v-if='file.loading')
                span.icon(style='font-size: 0.8em') Carregando...
              template(v-else)
                icon(name='file-lines')
            .file-name {{ file.file.name }}
        template(v-else)
          .file-item(:title='file.name')
            .actions
              button(@click='removeFile(file)' title='Remover')
                icon(name='trash')
            .preview(:style='`background-image: url(${file.links.preview})`')
            .file-name {{ file.name }}
  envelope-bottom-bar(
    @nextStep='next'
    @saveAndClose='saveAndClose'
    v-if='hasFiles'
  )
  file-local-loader-modal(
    :allowed-file-extensions='allowedFileExtensions'
    :allowed-file-type='allowedFileType'
    :max-file-size-mb='maxFileSizeMB'
    @selected-files='onSelectFiles'
    ref='fileUpload'
  )
  warning-save-and-close-envelope-modal(
    @discardChanges='discardChanges'
    @saveAndClose='saveAndClose'
    ref='saveAndCloseModal'
  )
    p.mb-0 Você deseja sair sem salvar os documentos inseridos?
</template>
<script>
import EnvelopeAcceptedFileTypes from '@/enums/EnvelopeAcceptedFileTypes';
import FileLocalLoaderModal from '@/ui/organisms/modals/FileLocalLoaderModal.vue';
import EnvelopesService from '@/modules/business/services/http/envelopes/EnvelopesService';
import Icon from '@/ui/atoms/Icon.vue';
import TopPageBar from '@/ui/organisms/TopPageBar.vue';
// eslint-disable-next-line max-len
import WarningSaveAndCloseEnvelopeModal from '@/modules/business/components/organisms/modals/WarningSaveAndCloseEnvelope.vue';
import ToastAlert from '@/modules/account/common/alert';
import EnvelopeBottomBar from '@/ui/organisms/EnvelopeBottom.vue';
import EnvelopeStepBar from '@/ui/organisms/EnvelopeStepBar.vue';

export default {
  name: 'EnvelopeSelectFiles',
  components: {
    FileLocalLoaderModal,
    Icon,
    TopPageBar,
    WarningSaveAndCloseEnvelopeModal,
    ToastAlert,
    EnvelopeBottomBar,
    EnvelopeStepBar,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === 'business.envelope.recipients' ||
      !this.hasChanges ||
      to.name === 'business.documents.draft'
    ) {
      next();
      return;
    }
    this.$refs.saveAndCloseModal.$refs.modal.open();
    this.nextRoute = next;
  },
  data() {
    return {
      files: [],
      dragging: false,
      maxFileSizeMB: 25,
      maxFilesPerEnvelope: 25,
      allowedFileType: [].concat(
        EnvelopeAcceptedFileTypes.mimeTypes,
        EnvelopeAcceptedFileTypes.extensions
      ),
      allowedFileExtensions: EnvelopeAcceptedFileTypes.extensions,
      nextRoute: null,
    };
  },
  created() {
    let pageTitle = 'Editar envelope';
    if (this.isNew) {
      pageTitle = 'Novo envelope';
    }
    this.$store.dispatch('page/setTitle', pageTitle);
    this.$store.dispatch('page/setHead', {
      title: pageTitle,
    });
  },
  async beforeMount() {
    if (this.isNew) {
      this.$store.state.envelope.documents.forEach((file) => {
        this.files.push(file);
      });
      this.$store.dispatch('envelope/addDocuments', []);
      return;
    }

    await this.loadFiles();
  },
  computed: {
    isNew() {
      return this.envelopeId === undefined;
    },
    envelopeId() {
      return this.$route.params.id;
    },
    hasFiles() {
      return this.files.length > 0;
    },
    hasChanges() {
      return this.hasFiles;
    },
    dontHaveCredits() {
      return !this.$store.getters['subscription/hasCredits'];
    },
    planIntervalName() {
      switch (this.$store.state.subscription.plan.plan.interval) {
        case 1:
          return 'mês';
        case 6:
          return 'semestre';
        case 12:
          return 'ano';
        default:
          return 'ciclo';
      }
    },
  },
  methods: {
    async loadFiles() {
      this.$loading(true);
      const res = await EnvelopesService.getExtendedEnvelope(
        this.$route.params.id
      );
      res.data.documents.forEach((f) => {
        const file = f;
        file.fromApi = true;
        this.files.push(file);
      });
      this.$loading(false);
    },
    openfileUploadModal() {
      this.$refs.fileUpload.$refs.modal.open();
    },
    async next() {
      let { envelopeId } = this;
      if (this.isNew) {
        envelopeId = await this.createEnvelope();
      }
      if (envelopeId) {
        this.$router.replace({
          name: 'business.envelope.recipients',
          params: { id: envelopeId, isNew: this.isNew },
        });
      }
    },
    async createEnvelope() {
      this.$loading(true);
      try {
        const [firstFile] = this.files;
        let envelopeTitle = '';
        if (firstFile.file) {
          envelopeTitle = firstFile.file.name;
        }
        if (firstFile.name) {
          envelopeTitle = firstFile.name;
        }
        if (envelopeTitle.length > 60) {
          envelopeTitle =
            envelopeTitle.substr(0, 50) +
            '...' +
            envelopeTitle.split('.').pop();
        }
        const response = await EnvelopesService.create({
          title: envelopeTitle,
        });
        if (response.status !== 201) {
          return false;
        }
        const envelope = response.data;
        await this.addFiles(envelope.id, this.files);
        this.$loading(false);
        return envelope.id;
      } catch (error) {
        ToastAlert.fireAlert(
          `Erro ao salvar envelope. ${error.response.data.message}`,
          {
            classes: 'alert-danger',
            styles:
              'background-color: #f8d7da; border-color: #F5C6CB; color: #721C24;',
            tag: 'httpAlert',
            icon: 'triangle-exclamation',
          }
        );
        this.$loading(false);
        return false;
      }
    },
    async addFiles(envelopeId, files) {
      if (files.length === 0) {
        return;
      }
      const f = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.id) {
          const res = await EnvelopesService.addDocument(envelopeId, file.id);
          f.push(res.data);
        }
        if (file.file) {
          const res = await EnvelopesService.addDocumentFile(
            envelopeId,
            file.file
          );
          f.push(res.data);
        }
        if (i + 1 === files.length) {
          return f;
        }
      }
    },
    async onSelectFiles(selectedFiles) {
      const concat = [].concat(this.files, selectedFiles);
      if (this.maxFilesPerEnvelope <= concat.length) {
        ToastAlert.fireAlert(
          `Não é possível adicionar mais que ${this.maxFilesPerEnvelope} arquivos por envelope.`,
          {
            classes: 'alert-warning',
            styles:
              'background-color: #FFFCE2; border-color: #EBE5A7; color: #635B00;',
            tag: 'httpAlert',
            icon: 'exclamation-circle',
          }
        );
        return;
      }
      if (this.isNew) {
        this.files = concat;
        return;
      }
      selectedFiles = selectedFiles.map((f) => ({
        ...f,
        ...{ loading: true },
      }));
      const oldFiles = this.files;
      this.files = [].concat(this.files, selectedFiles);
      this.$loading(true);
      try {
        const res = await this.addFiles(this.envelopeId, selectedFiles);
        this.files = [].concat(oldFiles, res);
      } catch (error) {
        this.files = oldFiles;
      }
      this.$loading(false);
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      this.$refs.fileUpload.$refs.modal.open();
      this.$refs.fileUpload.loadFile(event, true);
      this.dragging = false;
    },
    async removeFile(file) {
      if (!this.isNew) {
        await EnvelopesService.removeDocument(this.$route.params.id, file.id);
      }
      this.removeFileFromList(file);
    },
    removeFileFromList(file) {
      this.files = this.files.filter((f) => f !== file);
    },
    async saveAndClose() {
      if (this.isNew) {
        if (!(await this.createEnvelope())) {
          return;
        }
      }
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.push({ name: 'business.documents.draft' });
    },
    discardChanges() {
      if (this.nextRoute) {
        this.nextRoute();
        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="stylus" scoped>
.w-center
  margin: auto
  max-width: 960px
.card--file-loader
  text-align: center
  border: 2px dashed #3ECB90
  color: #013D52
  padding: 1rem
  box-shadow: 0 3px 5px #00000029
  &:hover
    background-color: #eaeaea
  .card-title
    font-size: 1.5rem
  .btn
    padding-left: 1rem
    padding-right: 1rem
  .dragenterClass
    cursor: grabbing
    cursor: -webkit-grabbing
    background:rgba(0,0,0,0.1)
    .notclickable
      pointer-events: none
      h3
        pointer-events: none
      p
        pointer-events: none
.files-list .file-item
  position: relative
  border: 1px solid #CCCCCC
  border-radius: 3px
  max-width: 250px
  margin: auto
  margin-left: 0
  .preview
    width: 100%
    height: 110px
    border-top-left-radius: 3px
    border-top-right-radius: 3px
    background-size: cover
    background-position: initial
    background-repeat: no-repeat
    text-align: center
    .icon
      color: #949494
      font-size: 2.5em
      margin: auto
  .file-name
    border-radius: 0 0 3px 3px
    font-size: 0.9rem
    margin-top: -6px
    padding: 10px 7px
    padding-right: 23px
    overflow: hidden
    text-overflow: ellipsis
    background: #fff
    white-space: nowrap
  .actions
    position: absolute
    bottom: 8px
    right: 4px
    button
      color: #949494

.btn-link-menu
  font-size: 1rem
  text-decoration: underline
  color: #0080a3 !important
  padding: 0

  .plan-details-link
    color: inherit
    text-decoration: none

    &:visited, &:hover, &:active
      color: inherit
      text-decoration: none
</style>
