<template lang="pug">
.row
  .col-12.mb-3
    ul#my-tab.nav.nav-tabs(role='tablist')
      li.nav-item(role='presentation')
        button#settings-tab.nav-link.active(
          aria-controls='settings'
          aria-selected='true'
          data-bs-target='#settings'
          data-bs-toggle='tab'
          role='tab'
          type='button'
        ) Configurações
    #organization-tab-content.tab-content.pt-3
      #settings.tab-pane.fade.show.active(
        aria-labelledby='settings-tab'
        role='tab-organization-settings'
      )
        form.form.row
          .col-12.col-sm-6
            .form-group
              label(for='name') Nome da Organização*
              .form-control-icon
                icon(:fix-width='true' name='sitemap')
                input#name.form-control(
                  autocomplete='off'
                  maxlength='50'
                  placeholder='Digite um nome'
                  type='name'
                  v-bind:class='{ "is-invalid": errors.name }'
                  v-model='organizationName'
                )
              small.text.text-danger {{ errors.name && errors.name[0] }}

          .col-12.col-sm-6
            .form-group
              label(for='company-segment') Segmento
              .form-control-icon
                icon(:fix-width='true' name='briefcase')
                input#company-segment.form-control(
                  autocomplete='off'
                  placeholder='Digite o segmento de sua empresa'
                  type='text'
                  v-bind:class='{ "is-invalid": errors.companySegment }'
                  v-model='companySegment'
                )
              small.text.text-danger {{ errors.companySegment && errors.companySegment[0] }}

          .col-12.col-sm-6
            .form-group
              label(for='user-position') Cargo
              .form-control-icon
                icon(:fix-width='true' name='user-tie')
                input#user-position.form-control(
                  autocomplete='off'
                  placeholder='Digite seu cargo'
                  type='text'
                  v-model='userPosition'
                )

          .col-12.col-sm-6
            .form-group
              label(for='company-name') Empresa
              .form-control-icon
                icon(:fix-width='true' name='building')
                input#company-name.form-control(
                  autocomplete='off'
                  placeholder='Digite o nome de sua empresa'
                  type='text'
                  v-bind:class='{ "is-invalid": errors.companyName }'
                  v-model='companyName'
                )
              small.text.text-danger {{ errors.companyName && errors.companyName[0] }}

          .col-12.text-end
            button.mt-2.btn.btn-secondary(
              :disabled='loading'
              @click='organizationCreate'
              type='button'
            ) Criar organização

    //- organization-remove-modal(:organization-id='organizationToEditId')
</template>

<script>
import Icon from '@/ui/atoms/Icon.vue';
import formHandler from '@/modules/account/common/formHandler';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      errors: {},
      organizationName: '',
      companyName: '',
      companySegment: '',
      userPosition: '',
      loading: false,
    };
  },
  methods: {
    organizationCreate() {
      formHandler.submit(
        this,
        () =>
          this.$store.dispatch('organizations/createItem', {
            name: this.organizationName,
          }),
        {
          fieldsByPropertyName: {
            name: 'name',
          },
          successHandler: (response) => {
            this.loading = false;
            this.close = true;
            this.organizationName = '';
            this.companyName = '';
            this.companySegment = '';
            this.userPosition = '';
            formHandler.defaultSuccess(response);
            this.$router.push('/business/').catch(() => {});
          },
        }
      );
    },
  },
  created() {
    this.$store.dispatch('page/setTitle', 'Nova Organização');
    this.$store.dispatch('page/setHead', { title: 'Nova Organização' });
  },
  mounted() {
    this.$store.dispatch('organizations/getItems');
  },
  computed: {
    organizations() {
      return this.$store.state.organizations.items;
    },
  },
};
</script>
